<template>
  <div id="app">
    <div class="hero is-fullheight has-text-centered">
      <snowflakes />

      <div class="hero-body">
        <div class="container">
          <h1 class="title is-1 has-text-primary">
            Hyvää joulua!
          </h1>
          <h2 class="title is-3">
            &mdash; Ami & Matu
          </h2>
          <h3 class="subtitle has-text-grey is-5">
            (+ Sidi & Isis)
          </h3>

          <b-button class="is-primary mt-6 is-hidden-desktop" @click="jump">
            <b-icon icon="heart" class="mr-0"></b-icon>
            Hyppää suoraan lahjaan
          </b-button>
        </div>
      </div>

      <b-button class="is-white is-large is-outlined is-rounded teaser" @click.prevent="next">
        <b-icon icon="chevron-down"></b-icon>
      </b-button>
    </div>

    <div class="container" ref="container">
      <div class="tile is-ancestor is-vertical is-gapless">
        <parent class="is-full is-vertical-tablet">
          <parent class="is-half-tablet">
            <photo :src="require('./assets/savitaipale_lyhty.jpg')"/>
          </parent>

          <parent class="is-vertical is-half-tablet">
            <photo :src="require('./assets/vantaa_valot.jpg')"/>
            <photo :src="require('./assets/piparit.jpg')"/>
          </parent>

          <parent class="is-full-tablet">
            <photo :src="require('./assets/savitaipale_tuli.jpg')"/>
          </parent>
        </parent>

        <parent class="is-half">
          <Form ref="form" />

          <photo :src="require('./assets/isis_joulu.jpg')"/>
        </parent>

        <parent class="is-full">
          <photo :src="require('./assets/vantaa_ovi.jpg')"/>
        </parent>

        <parent class="is-full">
          <photo :src="require('./assets/sidi.jpg')"/>
          <photo :src="require('./assets/isis.jpg')"/>
        </parent>

        <parent>
          <photo :src="require('./assets/sipoo_kirkko.jpg')" class="is-aspect-ratio-2"/>
        </parent>

        <parent class="is-full">
          <photo :src="require('./assets/vantaa_lumi.jpg')"/>
          <photo :src="require('./assets/porvoo_katu.jpg')"/>
          <photo :src="require('./assets/savitaipale_mokki.jpg')"/>
        </parent>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/Form";
import Photo from "@/components/Photo";
import Parent from "@/components/Parent";
import Snowflakes from "@/components/Snowflakes";

export default {
  name: 'App',
  components: {
    Snowflakes,
    Parent,
    Form,
    Photo
  },

  methods: {
    jump() {
      const form = this.$refs.form.$el;

      form.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    },

    next() {
      const container = this.$refs.container;

      container.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Great+Vibes|Playfair+Display|Roboto');

$primary: #e70078;
$danger: #E8A517;
$family-primary: 'Roboto';
$size-normal: 18px;
$grey-dark: hsl(0, 0%, 86%);
$grey-darker: hsl(0, 0%, 71%);

$grey-light: hsl(0, 0%, 21%);

$checkbox-border-color: hsl(0, 0%, 29%);

$input-placeholder-color: hsl(0, 0%, 48%);
$input-border-color: hsl(0, 0%, 48%);
$input-background-color: black;
$input-focus-border-color: $primary;
$input-focus-box-shadow-color: rgba($primary, 0.25);

@import "~bulma/sass/utilities/_all";
@import "~bulma";
@import "~buefy/src/scss/buefy";

html {
  background: black;
}

body {
  background-size: cover;
  background-attachment: fixed;
}

@media (orientation: landscape) {
  body {
    background-image: url('./assets/yotaivas_landscape.jpg');
  }
}

@media (orientation: portrait) {
  body {
    background-image: url('./assets/yotaivas_portrait.jpg');
  }
}

.title.is-1 {
  font-size: 6rem;
  font-family: 'Great Vibes', cursive;
}

h2, h3, h4 {
  font-family: 'Playfair Display', serif;
}

.hero {
  position: relative;
}

.container {
  padding: 2rem 0;
}

.is-gapless .tile.is-vertical > .tile.is-child {
  margin: 0 !important;
}

.is-gapless .tile.is-vertical > .tile.is-child:not(:last-child) {
  margin: 0 !important;
}

.is-gapless .tile.is-parent {
  padding: 0;
}

.box {
  background-color: rgba(black, 0.5)
}

.label {
  font-family: 'Playfair Display', serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.button {
  font-family: 'Playfair Display', serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.control-label {
  transition: color 0.3s ease;
  padding-right: .75rem;
}

.field:not(:last-child) {
  margin-bottom: 1rem;
}

.button.teaser {
  padding: 1em;
  font-size: 2em;
  position: absolute;
  bottom: 7.5%;
  left: calc(50% - 1em);
  transition: all .5s ease;
  animation: breathing 3s ease-out infinite;
  opacity: .5;
}

.button.teaser:hover {
  animation-play-state: paused;
  transform: scale(1.1);
  opacity: 1;
}


@keyframes breathing {
  0% {
    transform: scale(1);
    opacity: .4;
  }

  50% {
    transform: scale(1.1);
    opacity: .75;
  }

  100% {
    transform: scale(1);
    opacity: .4;
  }
}

@media (max-width: 1023px) {
  .button.teaser {
    display: none;
  }
}
</style>
