<template>
  <div class="tile is-child p-2">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tile"
}
</script>

<style scoped>
</style>
