<template>
  <tile class="px-4">
    <div class="box thank_you has-text-centered" v-if="submitted">
      <b-icon icon="check-circle"
              size="is-large"
              type="is-primary"
      />
      <h2 class="title is-2 mt-6">Lähetetty!</h2>
    </div>

    <form class="box mb-5" ref="form" v-if="!submitted" @submit.prevent="handleSubmit">
      <b-field label="Nimi">
        <b-input v-model="name" required
                 placeholder="Nimesi"
                 icon="user"
        ></b-input>
      </b-field>

      <b-field label="Sähköpostiosoite">
        <b-input v-model="email" required
                 type="email"
                 placeholder="esimerkki@maili.fi"
                 icon="envelope"
        ></b-input>
      </b-field>

      <b-field>
        <template #label>
          Paholaisenhillo
          <b-icon icon="seedling" type="is-success"></b-icon>
        </template>
        <b-checkbox v-model="paholaisenhillo.purkki">Purkki</b-checkbox>
        <b-checkbox v-model="paholaisenhillo.paljon">Paljon</b-checkbox>
      </b-field>

      <b-field>
        <template #label>
          Jotakin tulisempaa
          <b-icon icon="seedling" type="is-success"></b-icon>
        </template>
        <b-checkbox v-model="tulisempaa.purkki">Purkki</b-checkbox>
      </b-field>

      <b-field label="Aioli">
        <b-checkbox v-model="aioli.purkki">Purkki</b-checkbox>
      </b-field>

      <b-field label="Kermalikööri">
        <b-checkbox v-model="kermalikoori.pullo">Pullo</b-checkbox>
        <b-checkbox v-model="kermalikoori.vegaaninen">
          Vegaaninen
        </b-checkbox>
      </b-field>

      <b-button native-type="submit" :loading="sending"
                class="is-primary is-medium is-fullwidth my-3">
        Lähetä
      </b-button>

      <b-message class="is-primary">Lahjat pyritään toimittamaan kevättalven 2022 aikana.</b-message>
    </form>
  </tile>
</template>

<script>
import emailjs from 'emailjs-com';
import Tile from "@/components/Tile";

export default {
  name: "Form",
  components: {Tile},

  data() {
    return {
      name: '',
      email: '',
      paholaisenhillo: {
        purkki: false,
        paljon: false,
      },
      tulisempaa: {
        purkki: false
      },
      aioli: {
        purkki: false,
      },
      kermalikoori: {
        pullo: false,
        vegaaninen: false,
      },

      submitted: false,
      sending: false,
    }
  },

  methods: {
    handleSubmit() {
      this.submitted = true
      this.sending = true

      let data = Object.assign({}, this.$data)

      for (const field in data) {
        if (typeof data[field] === 'object') {
          for (const [key, value] of Object.entries(data[field])) {
            data[field][key] = value ? '✔' : ''
          }
        }
      }

      emailjs.send('varjohovi', 'joulu_2021', data, 'user_tYqboQWSTyX5nb4Abqtu3')
          .then(() => {
            this.sending = false;
          }, (error) => {
            alert(error.text)
            this.sending = false
            this.submitted = false;
          })
    }
  }
}
</script>

<style lang="scss">
.box.thank_you {
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon.is-large {
    height: 16rem;
    width: 16rem;
  }

  .svg-inline--fa {
    width: 100%;
  }
}

</style>
