import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'

import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faCheck,
    faCheckCircle,
    faChevronDown,
    faEnvelope,
    faExclamationCircle,
    faHeart, faSeedling,
    faUser
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faHeart, faExclamationCircle, faUser, faEnvelope, faCheckCircle, faCheck, faChevronDown, faSeedling);
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.config.productionTip = false

Vue.use(Buefy, {
    defaultIconComponent: 'vue-fontawesome',
    defaultIconPack: 'fas',
})

new Vue({
    render: h => h(App),
}).$mount('#app')
