<template>
  <div class="snowflakes">
    <snowflake v-for="flake in snowflakes"
               :key="flake.id"
               :mode="flake.mode"
               @melt="die(flake)"
    ></snowflake>
  </div>
</template>

<script>
import Snowflake from "@/components/Snowflake";

export default {
  name: "Snowflakes",
  components: {Snowflake},
  data() {
    return {
      snowflakes: []
    }
  },

  mounted() {
    this.create()
    this.create()
    this.create()
    this.create()

    window.setInterval(this.fall, 250)
  },

  methods: {
    create() {
      let flake = {
        id: Math.floor(Math.random() * (100000 - 1 + 1) + 1),
        mode: 'normal'
      }

      const rand = Math.random()
      if (rand < 0.2) {
        flake.mode = 'large'
      } else if (rand > 0.35) {
        flake.mode = 'small'
      }

      this.snowflakes.push(flake)
    },

    fall() {
      if (Math.random() < 0.2 && this.snowflakes.length < 15) {
        this.create()
      }
    },

    die(flake) {
      this.snowflakes = this.snowflakes.filter(f => {
        return f.id !== flake.id
      })
    }
  }
}
</script>

<style scoped>
.snowflakes {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}
</style>
