<template>
  <div class="gravity" ref="gravity"
       :class="this.mode" :style="gravityStyle">
    <img :src="require('@/assets/snowflake.svg')" alt=""
         class="snowflake"
         :style="snowflakeStyle"
    />
  </div>
</template>

<script>
export default {
  name: "Snowflake",

  props: {
    mode: {
      type: String,
      default: 'normal',
    }
  },

  data() {
    return {
      size: 0,
      zIndex: 1,
      position: {
        x: 0,
        y: 0,
      },
      fallingDuration: 0,
      rotationDuration: 0,

      translate: 0,
    }
  },

  created() {
    const container = this.$parent.$el;

    if (this.mode === 'large') {
      this.size = this.random(10, 14)
      this.zIndex = this.random(2, 4)
    } else if (this.mode !== 'small') {
      this.size = this.random(6, 10)
      this.zIndex = this.random(0, 1)
    } else {
      this.size = this.random(2, 5)
      this.zIndex = 0
    }

    this.position = {
      x: this.random(0, container.getBoundingClientRect().width + this.sizeInPixels + 16),
      y: -this.sizeInPixels - 16,
    }
    this.fallingDuration = this.size ** 2 - 2
    this.rotationDuration = this.size * this.random(5, 10)
  },

  mounted() {
    this.$refs.gravity.onanimationend = () => {
      this.$emit('melt')
    }

    setTimeout(this.nudge, 5000)
  },

  computed: {
    gravityStyle() {
      let opacity = 0.9

      if (this.mode === 'large') {
        opacity = 1
      } else if (this.mode === 'small') {
        opacity = 0.75
      }

      return {
        top: this.position.y + 'px',
        left: this.position.x + 'px',
        zIndex: this.zIndex,
        animationDuration: this.fallingDuration,
        opacity: opacity,
      }
    },

    snowflakeStyle() {
      return {
        width: this.size + 'rem',
        height: this.size + 'rem',
        animationDuration: this.rotationDuration,
        transform: `translateX(${this.translate})px`
      }
    },

    sizeInPixels() {
      return this.size * 16
    }
  },

  methods: {
    random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },

    nudge() {
      if (Math.random() < 0.3) {
        this.translate = this.random(-this.sizeInPixels, Math.floor(this.sizeInPixels * 1.5))
      }

      setTimeout(this.nudge, 3000)
    }
  },
}
</script>

<style>
.gravity {
  position: absolute;
  animation: falling 15s linear;
}

.gravity.small {
  animation-name: distantlyfalling;
}

.gravity.large {
  animation-name: meltingquickly;
}

.snowflake {
  animation: rotating 3s linear infinite;
  transition: transform 1s linear;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes falling {
  from {
    opacity: .9;
  }

  90% {
    transform: translateY(85vh) translateX(-20vw) scale(0.8);
    opacity: .65;
  }

  to {
    transform: translateY(95vh) translateX(-20vw) scale(.65);
    opacity: 0;
  }
}

@keyframes distantlyfalling {
  from {
    opacity: .8;
  }

  90% {
    transform: translateY(55vh) translateX(-20vw) scale(0.8);
    opacity: .55;
  }

  to {
    transform: translateY(65vh) translateX(-20vw) scale(.5);
    opacity: 0;
  }
}

@keyframes meltingquickly {
  from {
    opacity: 1;
  }

  90% {
    transform: translateY(75vh) translateX(-20vw) scale(0.5);
    opacity: .8;
  }

  to {
    transform: translateY(85vh) translateX(-20vw) scale(.3);
    opacity: 0;
  }
}
</style>
