<template>
  <div class="tile is-parent">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Parent"
}
</script>

<style scoped>

</style>
