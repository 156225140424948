<template>
  <tile>
    <div class="block" :style="style"></div>
  </tile>
</template>

<script>
import Tile from "@/components/Tile";

export default {
  name: "Photo",
  components: {Tile},
  props: {
    src: {
      type: String,
      default: undefined
    }
  },

  computed: {
    style() {
      let style = {};

      if (this.src === undefined) {
        style.backgroundImage = `url(https://via.placeholder.com/600)`
      } else {
        style.backgroundImage = `url(${this.src})`
      }

      return style
    }
  }
}
</script>

<style scoped lang="scss">
.tile {
  height: 100%;
  width: 100%;
}

.block {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  background-position: center;
  background-size: cover;
}

.is-aspect-ratio-2 {
  .block {
    aspect-ratio: 2 / 1;
  }
}
</style>
